// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-bermuda-dunes-js": () => import("./../../../src/pages/bermuda-dunes.js" /* webpackChunkName: "component---src-pages-bermuda-dunes-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cathedral-city-js": () => import("./../../../src/pages/cathedral-city.js" /* webpackChunkName: "component---src-pages-cathedral-city-js" */),
  "component---src-pages-coachella-js": () => import("./../../../src/pages/coachella.js" /* webpackChunkName: "component---src-pages-coachella-js" */),
  "component---src-pages-coachella-kidney-institute-js": () => import("./../../../src/pages/coachella-kidney-institute.js" /* webpackChunkName: "component---src-pages-coachella-kidney-institute-js" */),
  "component---src-pages-dialysis-services-js": () => import("./../../../src/pages/dialysis-services.js" /* webpackChunkName: "component---src-pages-dialysis-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indian-wells-js": () => import("./../../../src/pages/indian-wells.js" /* webpackChunkName: "component---src-pages-indian-wells-js" */),
  "component---src-pages-indio-coachella-valley-nephrology-js": () => import("./../../../src/pages/indio-coachella-valley-nephrology.js" /* webpackChunkName: "component---src-pages-indio-coachella-valley-nephrology-js" */),
  "component---src-pages-indio-js": () => import("./../../../src/pages/indio.js" /* webpackChunkName: "component---src-pages-indio-js" */),
  "component---src-pages-indio-kidney-institute-js": () => import("./../../../src/pages/indio-kidney-institute.js" /* webpackChunkName: "component---src-pages-indio-kidney-institute-js" */),
  "component---src-pages-kidney-nutrition-dialysis-diet-js": () => import("./../../../src/pages/kidney-nutrition-dialysis-diet.js" /* webpackChunkName: "component---src-pages-kidney-nutrition-dialysis-diet-js" */),
  "component---src-pages-la-quinta-js": () => import("./../../../src/pages/la-quinta.js" /* webpackChunkName: "component---src-pages-la-quinta-js" */),
  "component---src-pages-la-quinta-kidney-center-js": () => import("./../../../src/pages/la-quinta-kidney-center.js" /* webpackChunkName: "component---src-pages-la-quinta-kidney-center-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-mecca-js": () => import("./../../../src/pages/mecca.js" /* webpackChunkName: "component---src-pages-mecca-js" */),
  "component---src-pages-nephrology-js": () => import("./../../../src/pages/nephrology.js" /* webpackChunkName: "component---src-pages-nephrology-js" */),
  "component---src-pages-palm-desert-js": () => import("./../../../src/pages/palm-desert.js" /* webpackChunkName: "component---src-pages-palm-desert-js" */),
  "component---src-pages-palm-springs-js": () => import("./../../../src/pages/palm-springs.js" /* webpackChunkName: "component---src-pages-palm-springs-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rancho-mirage-coachella-valley-nephrology-js": () => import("./../../../src/pages/rancho-mirage-coachella-valley-nephrology.js" /* webpackChunkName: "component---src-pages-rancho-mirage-coachella-valley-nephrology-js" */),
  "component---src-pages-rancho-mirage-js": () => import("./../../../src/pages/rancho-mirage.js" /* webpackChunkName: "component---src-pages-rancho-mirage-js" */),
  "component---src-pages-rancho-mirage-kidney-institute-js": () => import("./../../../src/pages/rancho-mirage-kidney-institute.js" /* webpackChunkName: "component---src-pages-rancho-mirage-kidney-institute-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-review-us-js": () => import("./../../../src/pages/review-us.js" /* webpackChunkName: "component---src-pages-review-us-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thermal-js": () => import("./../../../src/pages/thermal.js" /* webpackChunkName: "component---src-pages-thermal-js" */),
  "component---src-pages-thousand-palms-js": () => import("./../../../src/pages/thousand-palms.js" /* webpackChunkName: "component---src-pages-thousand-palms-js" */),
  "component---src-pages-traveling-on-dialysis-js": () => import("./../../../src/pages/traveling-on-dialysis.js" /* webpackChunkName: "component---src-pages-traveling-on-dialysis-js" */)
}

